import { Button, Checkbox, Collapse, notification, Space } from 'antd'
import { DownOutlined, RightOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons'
import styles from './CustomSettings.module.css'
import { useEffect, useMemo, useState } from 'react'
import PaymentMethodModal from '../payment-method-modal'
import { useDispatch, useSelector } from 'react-redux'
import { fetchPaymentMethods, updatePaymentMethodsStatus } from '../../store/companies/actions'

const { Panel } = Collapse

const CustomSettings = ({ companyId, isEditable }) => {
	const dispatch = useDispatch()
	const { paymentMethods } = useSelector(state => state.companiesReducer)
	const [customPaymentMethods, setCustomPaymentMethods] = useState([])
	const [defaultPaymentMethods, setDefaultPaymentMethods] = useState([])
	const [isPaymentMethodModalVisible, setIsPaymentMethodModalVisible] = useState(false)
	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null)
	const [methodMap, setMethodMap] = useState({})
	const [loading, setLoading] = useState(false)
	const hasModified = useMemo(() => {
		const methods = [...customPaymentMethods, ...defaultPaymentMethods]
		return methods.some(m => m.disabled !== methodMap[m.id])
	}, [customPaymentMethods, defaultPaymentMethods, methodMap])
	const allUnchecked = useMemo(() => {
		const methods = [...customPaymentMethods, ...defaultPaymentMethods]
		return methods.every(m => m.disabled)
	}, [customPaymentMethods, defaultPaymentMethods])

	useEffect(() => {
		if (companyId) {
			dispatch(fetchPaymentMethods(companyId))
		}
	}, [companyId])

	useEffect(() => {
		setPaymentMethods(paymentMethods)
	}, [paymentMethods])

	const setPaymentMethods = (methods) => {
		const defaultMethods = []
		const customMethods = []
		const internalMap = {}
		for (const method of methods) {
			if (method.default) {
				defaultMethods.push({ ...method })
			} else {
				customMethods.push({ ...method })
			}
			internalMap[method.id] = method.disabled
		}
		setMethodMap(internalMap)
		setDefaultPaymentMethods(defaultMethods)
		setCustomPaymentMethods(customMethods)
	}

	const onDefaultPaymentMethodChange = (method) => {
		const updatedMethods = defaultPaymentMethods.map(m => {
			if (m.id === method.id) {
				return {
					...m,
					disabled: !m.disabled
				}
			}
			return m
		})
		setDefaultPaymentMethods(updatedMethods)
	}

	const onCustomPaymentMethodChange = (method) => {
		const updatedMethods = customPaymentMethods.map(m => {
			if (m.id === method.id) {
				return {
					...m,
					disabled: !m.disabled
				}
			}
			return m
		})
		setCustomPaymentMethods(updatedMethods)
	}

	const onSave = async () => {
		try {
			setLoading(true)
			const payload = [...defaultPaymentMethods, ...customPaymentMethods].map(m => ({ id: m.id, disabled: m.disabled }))
			await dispatch(updatePaymentMethodsStatus(companyId, { statuses: payload }))
			notification.success({
				message: 'Payment Methods Saved Successfully!',
				placement: 'bottomLeft'
			})
		} catch (e) {
			console.error(e)
			notification.error({
				message: 'Unable to Save Payment Methods!',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setLoading(false)
		}
	}

	return (
		<>
			<div className={styles.container}>
				<Collapse
					ghost={true}
					expandIcon={({ isActive }) => isActive ? <DownOutlined /> : <RightOutlined />}
					style={{ fontSize: '18px' }}
				>
					<Panel
						header='Payment Methods'
						key='payment-method'
					>
						<div className={styles.content}>
							<div>
								Activate the payment methods required for your transactions and include up to 10 custom payment methods. The Activated payment methods will be available for use when adding payments to Sales Orders and Purchase Orders.
							</div>
							<div className={styles.paymentMethodsContainer}>
								<h3>Default Payment Methods</h3>
								<div className={styles.paymentMethods}>
									{
										defaultPaymentMethods.map(method => {
											return (
												<Checkbox
													key={method.id}
													style={{ marginBottom: '8px', marginLeft: '0px' }}
													checked={!method.disabled}
													disabled={!isEditable}
													onChange={() => onDefaultPaymentMethodChange(method)}
												>
													{method.name}
												</Checkbox>
											)
										})
									}
								</div>
							</div>
							<div className={styles.paymentMethodsContainer}>
								<h3>Custom Payment Methods</h3>
								<div className={styles.paymentMethods}>
									{
										customPaymentMethods.map(method => {
											return (
												<div key={method.id} className={styles.customPaymentMethod}>
													<Checkbox
														key={method.id}
														checked={!method.disabled}
														disabled={!isEditable}
														onChange={() => onCustomPaymentMethodChange(method)}
													>
														{method.name}
													</Checkbox>
													{
														isEditable &&
														<Button
															size='small'
															type='link'
															icon={<EditOutlined />}
															onClick={() => {
																setSelectedPaymentMethod(method)
																setIsPaymentMethodModalVisible(true)
															}}
														/>
													}
												</div>
											)
										})
									}
									{
										customPaymentMethods.length < 10 && isEditable &&
										<Button
											size='middle'
											type='ghost'
											icon={<PlusOutlined />}
											style={{ marginTop: '8px' }}
											disabled={customPaymentMethods.length >= 10}
											onClick={() => setIsPaymentMethodModalVisible(true)}
										>
											Add Payment Method
										</Button>
									}
								</div>
							</div>
						</div>
					</Panel>
				</Collapse>
			</div>
			{
				isEditable &&
				<div className={styles.footer}>
					<Space size='small' style={{ marginLeft: 'auto' }}>
						<Button
							type='ghost'
							loading={loading}
							onClick={() => setPaymentMethods(paymentMethods)}
						>
							Cancel
						</Button>
						<Button
							type='primary'
							loading={loading}
							disabled={!hasModified || allUnchecked}
							onClick={onSave}
						>
							Save
						</Button>
					</Space>
				</div>
			}
			<PaymentMethodModal
				companyId={companyId}
				data={selectedPaymentMethod}
				visible={isPaymentMethodModalVisible}
				onCancel={() => {
					setIsPaymentMethodModalVisible(false)
					setSelectedPaymentMethod(null)
				}}
			/>
		</>
	)
}

export default CustomSettings
