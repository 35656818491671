export const FETCH_COMPANIES_SUCCESS = 'fetch_companies_success'
export const FETCH_COMPANY_DETAILS_SUCCESS = 'fetch_company_details_success'
export const UPDATE_COMPANY_DETAILS_SUCCESS = 'update_company_details_success'
export const UPDATE_COMPANY_SUCCESS = 'update_company_success'
export const UPDATE_COMPANY_DELIVERY_PLAN_SETTINGS_SUCCESS = 'update_company_delivery_plan_settings_success'
export const UPDATE_COMPANY_PREFERENCES_SUCCESS = 'update_company_preferences_success'
export const SET_ADMIN_COMPANY_DETAILS = 'set_admin_company_details'
export const FETCH_SUBSCRIPTION_USAGES_SUCCESS = 'fetch_subscription_usages_success'
export const FETCH_PAYMENT_METHODS_SUCCESS = 'fetch_payment_methods_success'
export const ADD_PAYMENT_METHOD_SUCCESS = 'add_payment_method_success'
export const UPDATE_PAYMENT_METHOD_SUCCESS = 'update_payment_method_success'
export const UPDATE_PAYMENT_METHODS_STATUS_SUCCESS = 'update_payment_methods_status_success'
