import { destroy, get, patch, post } from './index'

export const Companies = {
	index: (
		{
			page = 0,
			searchTerm,
			disabled,
			industry,
			industryType,
			fromDate,
			toDate,
			sortOrder,
			subscriptionPlan,
			subscriptionPeriod,
			subscriptionStatus,
			newAccountsOnly,
			trialEndedOnly,
			canceledOnly
		} = {},
		abortSignal
	) =>
		get('/companies', {
			params: {
				page,
				searchTerm,
				disabled,
				industry,
				industryType,
				fromDate,
				toDate,
				sortOrder,
				subscriptionPlan,
				subscriptionPeriod,
				subscriptionStatus,
				newAccountsOnly,
				trialEndedOnly,
				canceledOnly
			},
			signal: abortSignal
		}),
	single: (id) =>
		get(`/companies/${id}`),
	update: (id, params) =>
		patch(`/companies/${id}`, params),
	updateDetails: (id, params) =>
		patch(`/companies/${id}/details`, params),
	updateDeliveryPlanSettings: (id, params) =>
		patch(`/companies/${id}/details/delivery-plans`, params),
	updateApiKey: (id) =>
		patch(`/companies/${id}/details/api-key`),
	fetchDeletedCounts: (id) =>
		get(`/companies/${id}/recycle-bin/counts`),
	updateAccountTier: (id, params) =>
		patch(`/companies/${id}/details/account-tier`, params),
	clearData: (id, {
		salesOrder,
		distributor,
		product,
		inventoryItem,
		purchaseOrder,
		vendor,
		report,
		vehicle,
		transportVendor,
		transportQuotation,
		deliveryPlan,
		expense
	} = {}) => destroy(`/companies/${id}/data`, {
		data: {
			salesOrder,
			distributor,
			product,
			inventoryItem,
			purchaseOrder,
			vendor,
			report,
			vehicle,
			transportVendor,
			transportQuotation,
			deliveryPlan,
			expense
		}
	}),
	updateSubscriptionUsageLimit: (cid, customSubscriptionLimit) =>
		patch(`/companies/subscription-limit/${cid}`, customSubscriptionLimit),
	updateDefaultSubscriptionUsageLimits: (cid, defaultSubscriptionLimits) =>
		patch(`/companies/${cid}/default-subscription-limits`, defaultSubscriptionLimits),
	fetchDefaultSubscriptionLimits: () =>
		get('/companies/default-subscription-limits)'),
	updatePreferences: (id, params) =>
		patch(`/companies/${id}/preferences`, params),
	aggregates: () =>
		get('/companies/aggregates'),
	getTransactionStats: (
		{
			page,
			pageSize,
			subscriptionPlan,
			subscriptionStatus,
			fromDate,
			orderBy
		},
		abortSignal
	) =>
		get('/companies/transaction-stats', {
			params: {
				page,
				pageSize,
				subscriptionPlan,
				subscriptionStatus,
				fromDate,
				orderBy
			},
			signal: abortSignal
		}),
	fetchTransactionHistory: (companyId, startDate, numberOfMonths) =>
		get(`/companies/${companyId}/transaction-history`, {
			params: {
				startDate,
				numberOfMonths
			}
		}),
	fetchPaymentMethods: (companyId, params = {}) =>
		get(`/companies/${companyId}/payment-methods`, { params }),
	addPaymentMethod: (companyId, data) =>
		post(`/companies/${companyId}/payment-methods`, data),
	updatePaymentMethod: (companyId, id, data) =>
		patch(`/companies/${companyId}/payment-methods/${id}`, data),
	updatePaymentMethodsStatus: (companyId, data) =>
		patch(`/companies/${companyId}/payment-methods/status`, data)
}
