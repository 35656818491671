import { Fragment, useEffect, useMemo, useState } from 'react'
import GeneralSettings from './general'
import SubTab from '../sub-tab'
import styles from './CompanySettings.module.css'
import CustomSettings from './custom'
import { isValidUUID } from '../../utils'
import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'

const Tab = {
	GENERAL: 'general',
	CUSTOM: 'custom'
}
const tabs = [
	{
		title: 'General',
		value: Tab.GENERAL
	},
	{
		title: 'Custom Configuration',
		value: Tab.CUSTOM
	}
]

const CompanySettings = () => {
	const [activeTab, setActiveTab] = useState(Tab.GENERAL)
	const router = useRouter()
	const { query } = router
	const { params } = query
	const { userProfile, permissions } = useSelector(state => state.authReducer)
	const id = useMemo(() => {
		if (params && params.length > 0) {
			if (isValidUUID(params[0])) {
				return params[0]
			} else {
				return undefined
			}
		} else {
			return undefined
		}
	}, [params])

	const companyId = useMemo(() => {
		const companyId = id ? id : userProfile ? userProfile.companyId : ''
		return companyId
	}, [id, userProfile])

	const isEditable = useMemo(() => {
		return permissions.editCompanySettings === true
	}, [permissions])

	useEffect(() => {
		if (permissions.viewCompanySettings === false) {
			router.push('/company/general')
		}
	}, [permissions])

	return (
		<Fragment>
			<div className={styles.tabs}>
				{
					tabs.map(tab => {
						return (
							<SubTab
								key={tab.value}
								active={activeTab === tab.value}
								onClick={() => setActiveTab(tab.value)}
							>
								{tab.title}
							</SubTab>
						)
					})
				}
			</div>
			{
				activeTab === Tab.GENERAL ? <GeneralSettings companyId={companyId} /> : null
			}
			{
				activeTab === Tab.CUSTOM ? <CustomSettings companyId={companyId} isEditable={isEditable} /> : null
			}
		</Fragment>
	)
}

export default CompanySettings
