import styles from './PaymentMethod.module.css'
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal, notification } from 'antd'
import TextInput from '../text-input'
import { useDispatch } from 'react-redux'
import { addPaymentMethod, updatePaymentMethod } from '../../store/companies/actions'

const PaymentMethodModal = ({
	data,
	companyId,
	visible,
	onCancel
}) => {
	const dispatch = useDispatch()
	const [isLoading, setIsLoading] = useState(false)
	const [name, setName] = useState('')

	useEffect(() => {
		if (data) {
			setName(data.name)
		}
	}, [data])

	const cancel = () => {
		setName('')
		onCancel()
	}

	const onAdd = async () => {
		setIsLoading(true)
		try {
			await dispatch(addPaymentMethod(companyId, { name }))
			notification.success({
				message: 'Payment Method Added Successfully!',
				placement: 'bottomLeft'
			})
			cancel()
		} catch (e) {
			notification.error({
				message: 'Unable to Add Payment Method!',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsLoading(false)
		}
	}

	const onUpdate = async () => {
		setIsLoading(true)
		try {
			await dispatch(updatePaymentMethod(companyId, data.id, { name }))
			notification.success({
				message: 'Payment Method Updated Successfully!',
				placement: 'bottomLeft'
			})
			cancel()
		} catch (e) {
			notification.error({
				message: 'Unable to Update Payment Method!',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsLoading(false)
		}
	}

	const renderFooter = () => {
		return (
			<div className={styles.footer}>
				<Button onClick={cancel}>
					Cancel
				</Button>
				<Button
					type='primary'
					loading={isLoading}
					disabled={name.length === 0 || name.length > 25 || isLoading || (data && data.name === name?.trim())}
					onClick={data ? onUpdate : onAdd}
				>
					{data ? 'Update' : 'Add'}
				</Button>
			</div>
		)
	}

	return (
		<Modal
			visible={visible}
			title={data ? 'Edit Payment Method' : 'Add Payment Method'}
			onCancel={cancel}
			footer={renderFooter()}
		>
			<TextInput
				title='Payment Method Name'
				placeholder='Enter payment method name'
				value={name}
				onChange={e => setName(e.target.value)}
			/>
			{
				name.length > 25 &&
					<p className={styles.error}>
						Payment method name should not exceed 25 characters
					</p>
			}
		</Modal>
	)
}

PaymentMethodModal.propTypes = {
	companyId: PropTypes.string,
	data: PropTypes.object,
	visible: PropTypes.bool,
	onCancel: PropTypes.func
}

PaymentMethodModal.defaultProps = {
	data: {},
	companyId: '',
	visible: false,
	onCancel: () => {}
}

export default PaymentMethodModal
