
export const preferenceKeys = {
	IS_DEFAULT_SHIPPING_DATE_ENABLED: {
		key: 'isDefaultShippingDateEnabled',
		value: false
	},
	IS_DUPLICATE_PENDING_ORDER_CHECK_ENABLED: {
		key: 'isDuplicatePendingOrderCheckEnabled',
		value: true
	},
	IS_DUPLICATE_PROCESSING_ORDER_CHECK_ENABLED: {
		key: 'isDuplicateProcessingOrderCheckEnabled',
		value: false
	},
	DATE_TIME_DISPLAY_FORMAT: {
		key: 'dateTimeDisplayFormat',
		value: 'date_time'
	},
	PRODUCT_QUANTITY_INPUT_FORMAT: {
		key: 'productQuantityInputFormat',
		value: 'integer'
	},
	PRODUCT_UPSELL_MODE: {
		key: 'productUpsellMode',
		value: 'edit_order'
	},
	PRODUCT_LIST_VIEW_MODE: {
		key: 'productListViewMode',
		value: 'grouped_variant'
	},
	IS_FAST_ACTION_IN_TRANSIT_ENABLED: {
		key: 'isFastActionInTransitEnabled',
		value: true
	},
	IS_FAST_ACTION_IN_TRANSIT_FROM_APPROVED_ENABLED: {
		key: 'isFastActionInTransitFromApprovedEnabled',
		value: true
	},
	IS_FAST_ACTION_IN_TRANSIT_FROM_PROCESSING_ENABLED: {
		key: 'isFastActionInTransitFromProcessingEnabled',
		value: false
	},
	IS_FAST_ACTION_IN_TRANSIT_FROM_SHIPPED_ENABLED: {
		key: 'isFastActionInTransitFromShippedEnabled',
		value: false
	},
	IS_FAST_ACTION_APPROVED_ENABLED: {
		key: 'isFastActionApprovedEnabled',
		value: true
	},
	FAST_ACTION_APPROVED_INVENTORY_MODE: {
		key: 'fastActionApprovedInventoryMode',
		value: 'check_inventory'
	},
	IS_FAST_ACTION_DELIVERED_PAYMENT_COLLECTED_ENABLED: {
		key: 'isFastActionDeliveredPaymentCollectedEnabled',
		value: true
	},
	VIEW_LOCATION_TAGS_MODE: {
		key: 'viewLocationTagsMode',
		value: 'district'
	},
	VIEW_LOCATION_TAGS_APPROVED_ENABLED: {
		key: 'viewLocationTagsApprovedEnabled',
		value: true
	},
	VIEW_LOCATION_TAGS_PROCESSING_ENABLED: {
		key: 'viewLocationTagsProcessingEnabled',
		value: true
	},
	VIEW_LOCATION_TAGS_SHIPPED_ENABLED: {
		key: 'viewLocationTagsShippedEnabled',
		value: true
	},
	VIEW_DELIVERY_PARTNER_TAGS: {
		key: 'viewDeliveryPartnerTags',
		value: true
	},
	VIEW_CANCEL_REASON_TAGS: {
		key: 'viewCancelReasonTags',
		value: true
	},
	STORE_CUSTOMER_EMAIL_ENABLED: {
		key: 'storeCustomerEmailEnabled',
		value: true
	},
	ASSIGN_BRANCH_TO_ORDER_ENABLED: {
		key: 'assignBranchToOrderEnabled',
		value: false
	},
	FILTER_ORDER_BY_BRANCH_ENABLED: {
		key: 'filterOrderByBranchEnabled',
		value: false
	},
	STORE_CUSTOMER_ADDITIONAL_INFORMATION_ENABLED: {
		key: 'storeCustomerAdditionalInformationEnabled',
		value: true
	},
	WEBSITE_ORDERS_DELIVERY_FEE_ENABLED: {
		key: 'websiteOrdersDeliveryFeeEnabled',
		value: false
	},
	MANUAL_ORDERS_DELIVERY_FEE_ENABLED: {
		key: 'manualOrdersDeliveryFeeEnabled',
		value: false
	},
	STORE_TRANSACTION_ID_FOR_PAYMENTS_ENABLED: {
		key: 'storeTransactionIdForPaymentsEnabled',
		value: false
	}
}

export const mapPreferenceStateToKey = (state) => {
	return Object.keys(preferenceKeys).find(
		(key) => preferenceKeys[key].key === state
	)
}
