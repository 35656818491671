import { Companies } from '../../services/api/companies'
import { Permissions } from '../../services/api/permissions'
import {
	FETCH_COMPANIES_SUCCESS,
	FETCH_COMPANY_DETAILS_SUCCESS,
	UPDATE_COMPANY_DETAILS_SUCCESS,
	UPDATE_COMPANY_SUCCESS,
	UPDATE_COMPANY_DELIVERY_PLAN_SETTINGS_SUCCESS,
	UPDATE_COMPANY_PREFERENCES_SUCCESS,
	SET_ADMIN_COMPANY_DETAILS,
	FETCH_SUBSCRIPTION_USAGES_SUCCESS,
	FETCH_PAYMENT_METHODS_SUCCESS,
	ADD_PAYMENT_METHOD_SUCCESS,
	UPDATE_PAYMENT_METHOD_SUCCESS,
	UPDATE_PAYMENT_METHODS_STATUS_SUCCESS
} from './action-types'

export function fetchCompanies ({ page, searchTerm, disabled, industry, industryType, fromDate, toDate, sortOrder, subscriptionPlan, subscriptionPeriod, subscriptionStatus, newAccountsOnly, trialEndedOnly, canceledOnly }, abortSignal) {
	return async (dispatch) => {
		const response = await Companies.index({ page, searchTerm, disabled, industry, industryType, fromDate, toDate, sortOrder, subscriptionPlan, subscriptionPeriod, subscriptionStatus, newAccountsOnly, trialEndedOnly, canceledOnly }, abortSignal)
		dispatch({ type: FETCH_COMPANIES_SUCCESS, payload: { data: response.data } })
	}
}

export function fetchCompanyDetails (id) {
	return async (dispatch) => {
		const response = await Companies.single(id)
		dispatch({ type: FETCH_COMPANY_DETAILS_SUCCESS, payload: { data: response.data, id } })
	}
}

export function updateCompanyDetails (id, params) {
	return async (dispatch) => {
		const response = await Companies.updateDetails(id, params)
		dispatch({ type: UPDATE_COMPANY_DETAILS_SUCCESS, payload: { data: response.data, id } })
	}
}

export function updateDeliveryPlanSettings (id, params) {
	return async (dispatch) => {
		const response = await Companies.updateDeliveryPlanSettings(id, params)
		dispatch({ type: UPDATE_COMPANY_DELIVERY_PLAN_SETTINGS_SUCCESS, payload: { data: response.data, id } })
	}
}

export function updateCompany (id, params) {
	return async (dispatch) => {
		const response = await Companies.update(id, params)
		dispatch({ type: UPDATE_COMPANY_SUCCESS, payload: { data: response.data, id } })
	}
}

export function updatePreferences (id, params) {
	return async (dispatch) => {
		const response = await Companies.updatePreferences(id, params)
		dispatch({ type: UPDATE_COMPANY_PREFERENCES_SUCCESS, payload: { data: response.data, id } })
	}
}

export function setAdminCompanyDetails (id, companyDetails) {
	return async (dispatch) => {
		dispatch({ type: SET_ADMIN_COMPANY_DETAILS, payload: { data: companyDetails, id } })
	}
}

export function fetchSubscriptionUsage (companyId) {
	return async (dispatch) => {
		const response = await Permissions.fetchSubscriptionUsage(companyId)
		dispatch({ type: FETCH_SUBSCRIPTION_USAGES_SUCCESS, payload: { data: response.data } })
	}
}

export function fetchPaymentMethods (companyId) {
	return async (dispatch) => {
		const { data } = await Companies.fetchPaymentMethods(companyId)
		dispatch({ type: FETCH_PAYMENT_METHODS_SUCCESS, payload: { data: data.results } })
	}
}

export function addPaymentMethod (companyId, params) {
	return async (dispatch) => {
		const { data } = await Companies.addPaymentMethod(companyId, params)
		dispatch({ type: ADD_PAYMENT_METHOD_SUCCESS, payload: { data } })
	}
}

export function updatePaymentMethod (companyId, paymentMethodId, params) {
	return async (dispatch) => {
		const { data } = await Companies.updatePaymentMethod(companyId, paymentMethodId, params)
		dispatch({ type: UPDATE_PAYMENT_METHOD_SUCCESS, payload: { data, id: paymentMethodId } })
	}
}

export function updatePaymentMethodsStatus (companyId, params) {
	return async (dispatch) => {
		const { data } = await Companies.updatePaymentMethodsStatus(companyId, params)
		dispatch({ type: UPDATE_PAYMENT_METHODS_STATUS_SUCCESS, payload: { data } })
	}
}
