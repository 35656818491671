import { HYDRATE } from 'next-redux-wrapper'
import { FETCH_COMPANIES_SUCCESS, FETCH_COMPANY_DETAILS_SUCCESS, UPDATE_COMPANY_DETAILS_SUCCESS, UPDATE_COMPANY_SUCCESS, UPDATE_COMPANY_DELIVERY_PLAN_SETTINGS_SUCCESS, SET_ADMIN_COMPANY_DETAILS, FETCH_SUBSCRIPTION_USAGES_SUCCESS, FETCH_PAYMENT_METHODS_SUCCESS, ADD_PAYMENT_METHOD_SUCCESS, UPDATE_PAYMENT_METHOD_SUCCESS, UPDATE_PAYMENT_METHODS_STATUS_SUCCESS } from './action-types'
import { ADD_SALES_ORDERS_SUCCESS } from '../sales-orders/action-types'
import { ADD_PURCHASE_ORDERS_SUCCESS } from '../purchase-orders/action-types'

const initialState = {
	companies: [],
	totalCount: 0,
	page: 0,
	companyDetails: {},
	paymentMethods: [],
	subscriptionUsage: {
		createSalesOrdersPerMonth: {
			limit: 0,
			count: 0,
			exceeded: false
		},
		createPurchaseOrdersPerMonth: {
			limit: 0,
			count: 0,
			exceeded: false
		}
	}
}

const companiesReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case HYDRATE: {
			return { ...state, ...payload }
		}
		case FETCH_COMPANIES_SUCCESS: {
			return {
				...state,
				companies: payload.data.results,
				totalCount: payload.data.count,
				page: +payload.data.page
			}
		}
		case FETCH_COMPANY_DETAILS_SUCCESS: {
			const newState = { ...state }
			newState.companyDetails[payload.id] = payload.data
			return newState
		}
		case UPDATE_COMPANY_DELIVERY_PLAN_SETTINGS_SUCCESS:
		case UPDATE_COMPANY_DETAILS_SUCCESS: {
			const newState = window.structuredClone(state)
			const company = newState.companyDetails[payload.id]
			if (company) {
				company.companyDetails = payload.data
			}
			return newState
		}
		case UPDATE_COMPANY_SUCCESS: {
			const newState = { ...state }
			newState.companies = newState.companies.map(company => company.id === payload.id ? payload.data : company)
			const company = newState.companyDetails[payload.id]
			if (company) {
				newState.companyDetails[payload.id] = Object.assign(company, payload.data)
			}
			return newState
		}
		case SET_ADMIN_COMPANY_DETAILS: {
			const newState = window.structuredClone(state)
			newState.companyDetails[payload.id].companyDetails = payload.data
			return newState
		}
		case FETCH_SUBSCRIPTION_USAGES_SUCCESS: {
			return {
				...state,
				subscriptionUsage: payload.data
			}
		}
		case FETCH_PAYMENT_METHODS_SUCCESS: {
			return {
				...state,
				paymentMethods: payload.data
			}
		}
		case ADD_PAYMENT_METHOD_SUCCESS: {
			return {
				...state,
				paymentMethods: [...state.paymentMethods, payload.data]
			}
		}
		case UPDATE_PAYMENT_METHOD_SUCCESS: {
			return {
				...state,
				paymentMethods: state.paymentMethods.map(method => method.id === payload.id ? payload.data : method)
			}
		}
		case UPDATE_PAYMENT_METHODS_STATUS_SUCCESS: {
			return {
				...state,
				paymentMethods: state.paymentMethods.map(method => payload.data.find(m => m.id === method.id) || method)
			}
		}
		case ADD_SALES_ORDERS_SUCCESS: {
			const subscriptionUsage = { ...state.subscriptionUsage }
			const previousCount = subscriptionUsage.createSalesOrdersPerMonth?.count || 0
			const count = previousCount + 1
			const limit = subscriptionUsage.createSalesOrdersPerMonth?.limit || 0
			return {
				...state,
				subscriptionUsage: {
					...subscriptionUsage,
					createSalesOrdersPerMonth: {
						...subscriptionUsage?.createSalesOrdersPerMonth,
						count,
						exceeded: count >= limit
					}
				}
			}
		}
		case ADD_PURCHASE_ORDERS_SUCCESS: {
			const subscriptionUsage = { ...state.subscriptionUsage }
			const previousCount = subscriptionUsage.createPurchaseOrdersPerMonth?.count || 0
			const count = previousCount + 1
			const limit = subscriptionUsage.createPurchaseOrdersPerMonth?.limit || 0
			return {
				...state,
				subscriptionUsage: {
					...subscriptionUsage,
					createPurchaseOrdersPerMonth: {
						...subscriptionUsage?.createPurchaseOrdersPerMonth,
						count,
						exceeded: count >= limit
					}
				}
			}
		}
		default:
			return state
	}
}

export default companiesReducer
