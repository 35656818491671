import Head from 'next/head'

const HeadComponent = () => {

	if (process.env.NEXT_PUBLIC_CUSTOM_DOMAIN === 'erp.ghorerbazar.com') {
		return (
			<Head>
				<title>GhorerBazar</title>
				<link rel='icon' href='https://storage.googleapis.com/nuport-dev.appspot.com/public/GhorerBazar.ico' />
			</Head>
		)
	}

	return (
		<Head>
			<title>Nuport</title>
			<link rel='icon' href='/favicon.ico' />
		</Head>
	)
}

export default HeadComponent
